import one from "../assets/1.JPG";
import two from "../assets/2.JPG";
import three from "../assets/3.JPG";
import four from "../assets/4.JPG";
import five from "../assets/5.JPG";
import six from "../assets/6.JPG";
import seven from "../assets/7.jpg";
import eight from "../assets/8.jpg";
import nine from "../assets/9.jpeg";
import ten from "../assets/11.jpeg";
import eleven from "../assets/10.jpeg";
import twelve from "../assets/12.jpeg";
import thirteen from "../assets/13.jpeg";

const services = [
  {
    title: "Engine Overhaul",
    description: `Is your engine struggling? Our expert overhaul service 
    restores performance, improving fuel efficiency and power 
    while addressing any underlying issues.`,
    src: ten,
    colour: "#E5B8E2",
  },
  {
    title: "Flushing Radiator",
    description: `Ensure your truck's cooling system is performing at its best.
    Our radiator flushing service removes dirt and debris, 
    preventing overheating and prolonging engine life.`,
    src: five,
    colour: "#A8E0D1",
  },
  {
    title: "Normal Service",
    description: `Regular maintenance is key longevity. 
    Our normal service includes oil changes, filter replacements, 
    and comprehensive inspections, ensuring peak condition.`,
    src: three,
    colour: "#FFB3C1",
  },
  {
    title: "Nozzle Service",
    description: `Optimize your fuel injection system with our nozzle service.
    We clean and test fuel nozzles,
    ensuring your engine runs smoothly and efficiently.`,
    src: four,
    colour: "#FFC1A6",
  },
  {
    title: "Gearbox Overhaul",
    description: `Shift with confidence! Our gearbox overhaul service enhances 
    transmission performance, extending its lifespan 
    and providing smoother shifts.`,
    src: two,
    colour: "#E5B8E2",
  },
  {
    title: "Tyre Repair & Highway Breakdown Assistance",
    description: `Get back on the road quickly! We offer expert 
    tyre repair and 24/7 breakdown assistance on highways, 
    ensuring you’re never left stranded.`,
    src: seven,
    colour: "#A9BDA2",
  },
  {
    title: "Changing Wire Rope",
    description: `Maintain safety and efficiency with 
    our wire rope replacement service. We ensure your ropes 
    are in top condition, preventing accidents and downtime.`,
    src: eleven,
    colour: "#F2E28C",
  },
  {
    title: "Welding Service",
    description: `Our skilled technicians provide high-quality 
    welding services for structural repairs, ensuring your truck 
    remains robust and reliable.`,
    src: one,
    colour: "#B2E6D4",
  },
  {
    title: "Cylinder Repair",
    description: `Improve engine performance with our
    cylinder repair service. We diagnose and fix cylinder
    issues, enhancing power and efficiency.`,
    src: eight,
    colour: "#B2E6D4",
  },
  {
    title: "Wiring",
    description: `From electrical repairs to full rewiring, we handle
    all aspects of wiring to ensure your truck’s systems are safe and functional.`,
    src: nine,
    colour: "#B2E6D4",
  },
  {
    title: "Calibration and Diagnosis for Scania Prime Movers",
    description: `Our specialized calibration and diagnostic services for Scania prime movers
    ensure optimal performance, helping you get the most out of your investment.`,
    src: six,
    colour: "#B2E6D4",
  },
  {
    title: "Wholesale Parts & Components",
    description: `Need parts? We wholesale a wide range of components for
    trailers and prime movers. Count on us for quality and reliability!`,
    src: twelve,
    colour: "#B2E6D4",
  },
  {
    title: "PUSPAKOM Preparation",
    description: `We prepare your vehicle for PUSPAKOM inspections,
    ensuring compliance with regulations and minimizing
    the risk of failed inspections.`,
    src: thirteen,
    colour: "#E6B3AC",
  },
];

export default services;
