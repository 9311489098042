import services from "../data/servicesData";
import Card from "../components/Card";
import { useScroll } from "framer-motion";
import { useEffect, useRef } from "react";
// import Lenis from "@studio-freight/lenis";

export default function Services() {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  //   useEffect(() => {
  //     const lenis = new Lenis();

  //     function raf(time) {
  //       lenis.raf(time);
  //       requestAnimationFrame(raf);
  //     }

  //     requestAnimationFrame(raf);
  //   });

  return (
    <main
      ref={container}
      style={{
        position: "relative",
      }}
      className="bg-gray-900 pb-[4rem]"
    >
      <div className="flex items-center justify-center px-[1rem]">
        <span className="font-semibold text-white text-7xl md:text-9xl mt-[50px]">
          Our Services
        </span>
      </div>
      {services.map((service, i) => {
        const targetScale = 1 - (services.length - i) * 0.05;
        return (
          <Card
            key={`p_${i}`}
            i={i}
            {...service}
            progress={scrollYProgress}
            range={[i * 0.08, 1]}
            targetScale={targetScale}
          />
        );
      })}
    </main>
  );
}
