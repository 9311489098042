import React from "react";
import whatssapLogo from "../assets/whatssap_icon.svg";

const WHATSSAP_LINK =
  "https://wa.me/60122237869?text=Hi%2C%0AI%20would%20like%20to%20know%20more%20about%20your%20services.";

export default function WhatssapButton(props) {
  const { customClass } = props;
  return (
    <a
      target="_blank"
      href={WHATSSAP_LINK}
      className={`w-fit bg-[#00CC63] text-white font-semibold text-center py-[14px] px-[16px] rounded-lg flex flex-row cursor-pointer ${customClass}`}
    >
      <img
        src={whatssapLogo}
        alt="Chat on WhatssApp"
        className="w-auto h-[25px] mr-[0.5rem]"
      />
      Chat on WhatssApp
    </a>
  );
}
