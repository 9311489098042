import React from "react";
import addressIcon from "../assets/address.png";
import whatssapIcon from "../assets/whatssap.png";
import WhatssapButton from "./WhatssapButton";

export default function ContactUs() {
  return (
    <div className="flex flex-col mt-[4rem]">
      <div className="flex flex-col px-[2rem] mb-12 lg:flex-row lg:px-[40px] lg:justify-evenly">
        <div className="flex flex-col space-y-4 w-full mb-6 lg:mb-0 lg:w-[50%]">
          <h1 className="font-semibold text-gray-900 text-3xl md:text-6xl">
            Get in Touch
          </h1>
          <p className="text-gray-700 text-xl">
            Ready to keep your trucks running smoothly? Contact us today for a
            consultation or to schedule a service. Let us help you maximize your
            fleet's potential!
          </p>
        </div>
        <div className="space-y-2 mb-6 mb:mb-0">
          <img
            src={addressIcon}
            alt="SR Trucking Address"
            className="h-[50px] w-auto"
          />
          <p className="text-gray-900 font-semibold text-2xl">Our Address</p>
          <p className="text-gray-700 text-xl">
            Jelutong <br />
            Penang
          </p>
        </div>
        <div className="space-y-2">
          <img
            src={whatssapIcon}
            alt="SR Trucking Whatssap"
            className="h-[50px] w-auto"
          />
          <p className="text-gray-900 font-semibold text-2xl">
            Our Contact Info
          </p>
          <p className="text-gray-700 text-xl">
            +6012-223-7869 <br />
            +6014-906-8703 <br />
            admin@sr-trucking.com
          </p>
          <WhatssapButton />
        </div>
      </div>
      <div className="">
        <iframe
          width="100%"
          height="400"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Jelutong,+(Penang)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps vehicle tracker</a>
        </iframe>
      </div>
    </div>
  );
}
