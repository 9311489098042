import React, { useRef, useEffect } from "react";

export default function VideoPlayer(props) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <video
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
      ref={videoRef}
      loop
      autoPlay
      muted
      playsInline
    >
      <source src={props.video} type="video/mp4" />
    </video>
  );
}
