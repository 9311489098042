import React from "react";
import missionBackground from "../assets/mission.jpg";

export default function MissionStatement() {
  return (
    <div
      className="flex justify-center space-y-[25px] flex-col relative items-center px-[16px] py-[100px]"
      style={{
        backgroundImage: `url(${missionBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <div className="absolute inset-0 bg-black bg-opacity-60 rounded-2xl"></div> */}
      <div
        className="flex flex-col w-full p-[32px] md:w-[50%] z-10
     bg-gray-300 rounded-xl bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 border border-gray-100
      "
      >
        <h1 className="text-white font-semibold text-6xl md:text-9xl">
          Our Mission
        </h1>
        <p
          className="text-white md:text-xl mt-4"
          style={{
            lineHeight: 2,
          }}
        >
          Our mission is to deliver exceptional truck maintenance and repair
          services that enhance vehicle reliability and longevity, ensuring
          businesses operate efficiently across Peninsular Malaysia.
        </p>
      </div>
      <div className="w-full sm:w-[80%] h-[2px] bg-gray-300 backdrop-filter backdrop-blur-lg bg-opacity-80"></div>
      <div
        className="flex flex-col w-full p-[32px] md:w-[50%] z-10
     bg-gray-300 rounded-xl bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 border border-gray-100
      "
      >
        <h1 className="text-white font-semibold text-6xl md:text-9xl">
          Our Vision
        </h1>
        <p
          className="text-white md:text-xl mt-4"
          style={{
            lineHeight: 2,
          }}
        >
          Our vision is to be Malaysia's leading provider of truck services,
          known for excellence and innovation, empowering businesses with
          top-notch maintenance solutions and logistics support.
        </p>
      </div>
    </div>
  );
}
