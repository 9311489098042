import { useTransform, motion, useScroll } from "framer-motion";
import { useRef } from "react";

const Card = ({
  i,
  title,
  description,
  src,
  colour,
  progress,
  range,
  targetScale,
}) => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"],
  });

  const imageScale = useTransform(scrollYProgress, [0, 1], [2, 1]);
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div
      ref={container}
      className="h-screen flex items-center justify-center sticky top-0"
    >
      <motion.div
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          scale,
        }}
        className="flex flex-col relative -translate-y-1/4 h-[85%] w-full mx-[1rem] rounded-2xl p-12 transform-origin-top md:mx-[4rem] lg:mx-72"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black via-gray-600 to-transparent opacity-70 rounded-2xl"></div>

        <h2 className="text-center m-0 text-5xl z-10 font-bold text-white md:text-7xl">
          {title}
        </h2>
        <div className="flex h-full z-10 w-full">
          <div className="relative top-10">
            <p className="text-center font-normal text-gray-100 text-xl md:text-4xl">
              {description}
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Card;
