import React from "react";

export default function MarqueeStates() {
  return (
    <div className="wrapper pb-[4rem] md:pb-[8rem] lg:pt-[4rem]">
      <div class="marquee-text fadeout-horizontal mb-4">
        <div class="marquee-text-track">
          {states.map((state) => {
            return <p>{state}</p>;
          })}
          {states.map((state) => {
            return <p aria-hidden="true">{state}</p>;
          })}
        </div>
      </div>
      <div class="marquee-text fadeout-horizontal">
        <div class="marquee-text-track [--direction:reverse]">
          {states.map((state) => {
            return <p>{state}</p>;
          })}
          {states.map((state) => {
            return <p aria-hidden="true">{state}</p>;
          })}
        </div>
      </div>
    </div>
  );
}

const states = [
  "Perlis",
  "Kedah",
  "Penang",
  "Perak",
  "Selangor",
  "Negeri Sembilan",
  "Malacca",
  "Johore",
  "Kelantan",
  "Terengganu",
  "Pahang",
];
