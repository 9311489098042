import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { TextParallaxContentExample } from "./components/TextParallaxContent";
import Carousell from "./components/HorizontalScroll";
import ParallaxContainer from "./components/ParallaxImage";
import MarqueeStates from "./components/MarqueeStates";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Services from "./components/Services";
import MissionStatement from "./components/MissionStatement";

function App() {
  return (
    <React.Fragment>
      <TextParallaxContentExample />
      <Services />
      <ParallaxContainer />
      <MarqueeStates />
      <MissionStatement />
      <ContactUs />
      <Footer />
    </React.Fragment>
  );
}

export default App;
