import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import container from "../assets/container.PNG";

export default function ParallaxContainer() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "-200%"]);
  const textY = useTransform(scrollYProgress, [0, 1], ["0%", "200%"]);

  return (
    <div
      ref={ref}
      className="w-full overflow-hidden relative flex flex-col place-items-center mb-[3rem] lg:mb-[4.8rem]"
    >
      <motion.img
        src={container}
        style={{ y: backgroundY }}
        className="h-[335px] md:h-[750px] w-auto absolute z-10"
        alt="storage container - SR Trucking"
      />
      <h1 className="sticky font-bold mt-[260px] text-center text-gray-900 text-4xl md:text-6xl md:mt-[400px] lg:mt-[500px] lg:text-9xl z-0">
        Logistics Services
      </h1>
      <p className="mt-6 text-center text-gray-700 text-2xl px-[1rem] md:px-[6rem]">
        Looking to transport products across Peninsular Malaysia? <br />
        Our logistics service, equipped with trailers, ensures timely and secure
        delivery of your goods.
      </p>
    </div>
  );
}
