import React from "react";

export default function Footer() {
  return (
    <footer class="bg-gray-800">
      <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2024{" "}
          <a href="https://flowbite.com/" class="hover:underline">
            SR Trucking Maintenance & Repair Sdn. Bhd.{" "}
          </a>{" "}
          All Rights Reserved.
        </span>
        <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <p class="hover:underline me-4 md:me-6">+6012-223-7869</p>
          </li>
          <li>
            <p class="hover:underline me-4 md:me-6">+6014-906-8703</p>
          </li>
          <li>
            <p class="hover:underline me-4 md:me-6">admin@sr-trucking.com</p>
          </li>
        </ul>
      </div>
    </footer>
  );
}
