import React, { useRef } from "react";
import logo from "../assets/srtrucking_logo.svg";
import smallLogo from "../assets/srtrucking_small_logo.svg";
import arrowDown from "../assets/arrow_down.svg";
import heroVideo from "../assets/hero_video.mp4";
import { FiArrowUpRight } from "react-icons/fi";
import { motion, useScroll, useTransform } from "framer-motion";
import WhatssapButton from "./WhatssapButton";
import VideoPlayer from "./VideoPlayer";

export const TextParallaxContentExample = () => {
  return (
    <div className="bg-white">
      <TextParallaxContent
        videoUrl={heroVideo}
        isHero={true}
      ></TextParallaxContent>
    </div>
  );
};

const IMG_PADDING = 12;

const TextParallaxContent = ({
  imgUrl,
  videoUrl,
  isHero,
  heading,
  subheading,
  children,
}) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
      }}
    >
      <div className="relative h-[150vh]">
        {imgUrl ? (
          <StickyImage imgUrl={imgUrl} />
        ) : (
          <StickyVideo videoUrl={videoUrl} />
        )}
        {isHero ? (
          <HeroCopy />
        ) : (
          <OverlayCopy heading={heading} subheading={subheading} />
        )}
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      className="sticky z-0 overflow-hidden rounded-3xl"
      ref={targetRef}
    >
      <motion.div
        style={{
          opacity,
        }}
        className="absolute inset-0 bg-neutral-950/70"
      />
    </motion.div>
  );
};

const StickyVideo = ({ videoUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 0.75], [0.75, 0]);

  return (
    <motion.div
      style={{
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
        overflow: "hidden", // Ensures that the video doesn't overflow the container
      }}
      className="sticky z-0 rounded-3xl"
      ref={targetRef}
    >
      <VideoPlayer video={videoUrl} />
      <motion.div
        style={{
          opacity,
        }}
        className="absolute inset-0 bg-neutral-950/70"
      />
    </motion.div>
  );
};

const OverlayCopy = ({ heading, subheading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      ref={targetRef}
      style={{ y, opacity }}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4">{subheading}</p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const HeroCopy = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      ref={targetRef}
      style={{ y, opacity }}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white p-[1rem]"
    >
      <p className="mb-2 text-center text-sm md:text-xl md:mb-4">
        Reliable, Efficient, and Comprehensive Services for All Your Trucking by
      </p>
      <div className="flex flex-row items-center space-x-2 md:space-x-4">
        <img
          src={smallLogo}
          alt="SR TRUCKING LOGO"
          className="w-auto h-[30px] md:h-[50px] lg:h-[100px]"
        />
        <p className="text-center text-[#b7ff64] text-4xl font-bold md:text-6xl lg:text-9xl">
          SR Trucking
        </p>
      </div>
      <WhatssapButton customClass="!w-auto !mt-6" />
      <div className="absolute bottom-[140px] flex flex-row opacity-70 ml-[-5px]">
        <img src={arrowDown} alt="scroll down" className="w-[20px] h-[20px]" />
        <p className="text-sm font-bold ml-[16px]">scroll for more</p>
      </div>
    </motion.div>
  );
};

const ExampleContent = () => {
  return (
    <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
      <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
        Additional content explaining the above card here.
      </h2>
      <div className="col-span-1 md:col-span-8">
        <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
          officia deserunt mollit anim id est laborum.
        </p>
        <button className="w-full rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hovering:bg-neutral-700 md:w-fit">
          Learn more <FiArrowUpRight className="inline" />
        </button>
      </div>
    </div>
  );
};
